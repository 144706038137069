import React from 'react';
import { Helmet } from 'react-helmet';
import globalStyles from './styles/GlobalStyles';

export const wrapRootElement = ({ element }) => (
  <React.Fragment>
    {element}
  </React.Fragment>
);

export const wrapPageElement = ({ element, props }, pluginOptions) => {
  const { pageContext: { localizations = [] } = {} } = props;
  return (
    <React.Fragment>
      <Helmet>
        {localizations.map(({ lang, url }) => (
          <link rel='alternate' hrefLang={lang} href={url} />
        ))}
      </Helmet>
      {globalStyles}
      {element}
    </React.Fragment>
  );
};
