const { withPrefix } = require('gatsby');

let timers = [];
const appendScript = (scriptInfo, timeout = 1000) => {
  const timer = setTimeout(() => {
    const scriptElement = document.createElement('script');
    scriptElement.className = "_iub_cs_activate";
    scriptElement.type = "text/plain";
    scriptElement["data-iub-purposes"] = "2"
    if (typeof scriptInfo === 'string') scriptElement["data-suppressedsrc"] = scriptInfo;
    else {
      Object.entries(scriptInfo).map(
        ([key, value]) => (scriptElement[key] = value)
      );
    }

    document.body.appendChild(scriptElement);
  }, timeout);
  timers.push(timer);
};

exports.onInitialClientRender = (_, { paths }) => {
  if (paths.includes(window.location.pathname)) {
    appendScript(withPrefix('/freshdesk-chat-form.js'), 2000);
    appendScript(withPrefix('/freshdesk.js'), 2000);
    // widget lib must be loaded after freshdesk config files
    appendScript(
      { "data-suppressedsrc": 'https://wchat.freshchat.com/js/widget.js', async: true },
      4000
    );
  }
};

exports.onPreRouteUpdate = () => {
  timers.forEach((timer) => clearTimeout(timer));
};
