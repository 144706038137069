import { Global, css } from '@emotion/react';
import { withPrefix } from 'gatsby-link';
import fontFamily from '../constants/fontFamily';
export default (
  <Global
    styles={css`
      /* poppins-regular - latin */
      @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('${withPrefix(
          '/fonts/poppins-v15-latin/poppins-v15-latin-regular.eot'
        )}'); /* IE9 Compat Modes */
        src: local(''),
          url('${withPrefix(
              '/fonts/poppins-v15-latin/poppins-v15-latin-regular.eot'
            )}?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */
            url('${withPrefix(
              '/fonts/poppins-v15-latin/poppins-v15-latin-regular.woff2'
            )}')
            format('woff2'),
          /* Super Modern Browsers */
            url('${withPrefix(
              '/fonts/poppins-v15-latin/poppins-v15-latin-regular.woff'
            )}')
            format('woff'),
          /* Modern Browsers */
            url('${withPrefix(
              '/fonts/poppins-v15-latin/poppins-v15-latin-regular.ttf'
            )}')
            format('truetype'),
          /* Safari, Android, iOS */
            url('${withPrefix(
              '/fonts/poppins-v15-latin/poppins-v15-latin-regular.svg'
            )}#Poppins')
            format('svg'); /* Legacy iOS */
      }
      /* poppins-700 - latin */
      @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('${withPrefix(
          '/fonts/poppins-v15-latin/poppins-v15-latin-700.eot'
        )}'); /* IE9 Compat Modes */
        src: local(''),
          url('${withPrefix(
              '/fonts/poppins-v15-latin/poppins-v15-latin-700.eot'
            )}?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */
            url('${withPrefix(
              '/fonts/poppins-v15-latin/poppins-v15-latin-700.woff2'
            )}')
            format('woff2'),
          /* Super Modern Browsers */
            url('${withPrefix(
              '/fonts/poppins-v15-latin/poppins-v15-latin-700.woff'
            )}')
            format('woff'),
          /* Modern Browsers */
            url('${withPrefix(
              '/fonts/poppins-v15-latin/poppins-v15-latin-700.ttf'
            )}')
            format('truetype'),
          /* Safari, Android, iOS */
            url('${withPrefix(
              '/fonts/poppins-v15-latin/poppins-v15-latin-700.svg'
            )}#Poppins')
            format('svg'); /* Legacy iOS */
      }

      /* roboto-400 */
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('${withPrefix('/fonts/roboto/roboto-regular.ttf')}');
      }

      /* roboto-700 */
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('${withPrefix('/fonts/roboto/roboto-bold.ttf')}');
      }

      * {
        box-sizing: border-box;
        font-family: ${fontFamily};
      }

      body {
        margin: 0;
        font-size: 12px;
      }
      p {
        line-height: 1.6;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: 1.1;
        font-weight: 500;
      }
      h1 {
        font-size: 24px !important;
      }
      h2 {
        font-size: 21px !important;
      }
      h3 {
        font-size: 18px !important;
      }
      h4 {
        font-size: 16px !important;
      }
      h5 {
        font-size: 14px !important;
      }
      h6 {
        font-size: 12px !important;
      }
      a,
      .link {
        color: inherit;
        text-decoration: none !important;
      }
      .link:hover {
        cursor: pointer;
      }
      @media (min-width: 768px) {
        body {
          font-size: 16px !important;
        }

        h1 {
          font-size: 32px !important;
        }

        h2 {
          font-size: 28px !important;
        }

        h3 {
          font-size: 26px !important;
        }

        h4 {
          font-size: 24px !important;
        }

        h5 {
          font-size: 20px !important;
        }

        h6 {
          font-size: 18px !important;
        }
      }
    `}
  />
);
